
import auth from "../services/auth";
import validate from "../services/validator";
import router from "./../router";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import { defineComponent, reactive, ref, UnwrapRef, onMounted, createVNode } from "vue";
import { notification, Modal } from "ant-design-vue";
import {
 CheckCircleOutlined
} from "@ant-design/icons-vue";
import commonService from "../services/common";

interface FormState {
  agency_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  country: string;
  status: string;
}
export default defineComponent({
  components: {},
  setup() {
    const loading = ref<boolean>(false);
    const allCountries = ref([]);
    const formState: UnwrapRef<FormState> = reactive({
      agency_name: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      postal_code: "",
      country: "United Kingdom",
      status: 'active'
    });
    const rules = validate.signValidation;
    const formRef = ref();

    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const register = async (values: FormState) => {
      loading.value = true;
      try {
        values.status = 'active'
        const res = await auth.register(values);
        if (res.data.data.success) {
          formRef.value.resetFields();
          agencyCreatedPopup()
        }
        loading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
          if(error.response.data.email[0]){
             await notify("Error", error.response.data.email[0], "error");
           } else {
            await notify("Error", error.response.data.data.message, "error");
           }
        }
        loading.value = false;
      }
    };
    const handleFinishFailed = (errors: ValidateErrorEntity<FormState>) => {
      console.log("errors", errors);
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const filterOption = (input: string, option: any) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const getAllCountries = async () => {
      allCountries.value = [];
      try {
        const res = await commonService.getAllCountries();
        if (res.data.data.success) {
          allCountries.value = res.data.data.Countries;
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    const agencyCreatedPopup = async () => {
      Modal.confirm({
        title: 'Agency created successfully',
        icon: createVNode(CheckCircleOutlined),
        content: createVNode(
              'p',
              {},
              'Please check your Email for password and Login.'
            ),
        okText: 'Ok',
        class: 'delte-confirm-modal registerSucess',
        async onOk () {
          router.push("/login");
        }
      })
    }
    onMounted(async () => {
      getAllCountries();
    });
    return {
      formState,
      formRef,
      handleFinishFailed,
      register,
      resetForm,
      loading,
      rules,
      notify,
      filterOption,
      allCountries,
      agencyCreatedPopup,
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };
  },
});
